import React from 'react';
import {   Redirect } from 'react-router-dom';
import LoadingComponent from "./ReusableComponent/LoadingComponent";

export default class Header extends React.Component {
    state={
        redirect: false
    }

    
    componentDidMount() {
        let url = window.location;
        let access_token = new URLSearchParams(url.search).get('token');
        if(access_token)
        {
            localStorage.setItem('token', access_token);
            this.setState({redirect:true})
        }
    }
    render() {
        const {redirect} = this.state;
      return (
          <div>
              {redirect?
        <Redirect to="/internal/dashboard/home" />:
        <LoadingComponent />
              }
        </div>
        
      );
    }
  }