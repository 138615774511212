import InsightsComponent from "./ReusableComponent/InsightComponent";
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MoodIcon from '@material-ui/icons/Mood';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import RateReviewIcon from '@material-ui/icons/RateReview'
import _axios from '../helpers/Axios';
import NotificationComponent, { openSnackbar } from './ReusableComponent/NotificationComponent';
import ErrorComponent, { openErrorSnackbar } from './ReusableComponent/ErrorComponent';
import handleException from '../helpers/ErrorHandling';
import LoadingComponent from "./ReusableComponent/LoadingComponent";
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: '2rem'
    },
});
class ClientHealthMetric extends React.Component {
    state = {
        health_data: {},
        client_id: null
    }
    componentDidMount() {
        const client_id = this.props.client_id;
        this.setState({ client_id: client_id });
        this.initialLoad(`/api/dashboard/clients/health/${client_id}`);
    }

    initialLoad = async (client_health_url) => {
        try {
            const result = await _axios.get(client_health_url);
            this.setState({ health_data: result.data })
        } catch (error) {
            let err_msg = handleException(error, 'Failed to fetch health metrices');
            openErrorSnackbar({ message: err_msg });
        }

    }
    render() {
        const { classes } = this.props;
        const { health_data } = this.state
        if (health_data.data) {
            return (
                <div className={classes.root}>
                    <NotificationComponent />
                    <ErrorComponent />
                    <InsightsComponent titles={{ "engagament_score": "Engagement Score", "mood_score": "Mood Score", "response_rate_data": "Response Rate", "ptm_data": "People to Meet" }} insight_keys={["engagament_score", "mood_score", "response_rate_data", "ptm_data"]} insight_icon={{ "engagament_score": <OfflineBoltIcon style={{ fontSize: 40 }} />, "mood_score": <MoodIcon style={{ fontSize: 40 }} />, "response_rate_data": <RateReviewIcon style={{ fontSize: 40 }} />, "ptm_data": <LocalParkingIcon style={{ fontSize: 40 }} /> }} insights_data={health_data.data} health_metric={true} />
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <LoadingComponent />
            </div>
        )

    }

}


export default withStyles(useStyles)(ClientHealthMetric)