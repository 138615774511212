import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from "../styles/HomePageStyles";
import useDataApi from '../hooks/useDataFetchApi';
import InsightsComponent from "./ReusableComponent/InsightComponent";
import LoadingComponent from "./ReusableComponent/LoadingComponent";
import TodayIcon from '@material-ui/icons/Today';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PeopleIcon from '@material-ui/icons/People';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ErrorComponent, { openErrorSnackbar } from './ReusableComponent/ErrorComponent';
const releases_columns = [
  { id: 'release_name', label: 'Release Name', minWidth: 60 },
  { id: 'owner', label: 'Owner', minWidth: 60 },
  {
    id: 'live_date',
    label: 'Live Date',
    minWidth: 40,
  },
];

const launch_columns = [
  { id: 'title', label: 'Name', minWidth: 40 },
  { id: 'status', label: 'Subscription Type', minWidth: 80 },
  {
    id: 'launch_date',
    label: 'Launch Date',
    minWidth: 40,
  },
];

export default function HomePage() {
  const classes = useStyles();
   const [insightsData, setUrl] = useDataApi(`/api/dashboard/insights`);
   const [launchData, setInsightUrl] = useDataApi(`/api/dashboard/recent-launches`);
   const [releaseData, setReleaseUrl] = useDataApi(`/api/dashboard/latest-releases`);
  
  if (insightsData.data && launchData.data && releaseData.data) {
    return (
      <div className={classes.root}>
        <section className={classes.insight}>
          <Typography variant="h5" component="h5" className={classes.title} color="textSecondary" gutterBottom>
            Amber's Reach
        </Typography>
          <InsightsComponent titles={{ "clients": "Active Clients", "users": "Active Users", "reachouts": "Reachouts", "reminders": "Reminders" }} insight_keys={["clients", "users", "reachouts", "reminders"]} insight_icon={{ "clients": <PeopleIcon style={{ fontSize: 40 }} />, "users": <EmojiPeopleIcon style={{ fontSize: 40 }} />, "reachouts": <QuestionAnswerIcon style={{ fontSize: 40 }} />, "reminders": <TodayIcon style={{ fontSize: 40 }} /> }} insights_data={insightsData.data} />
        </section>
        <section>
          <Grid container spacing={3} justify={'space-between'}>
            <Grid item xs={12} sm={5}>
              <Paper style={{ maxHeight: 600, overflow: 'auto' }}>
                <Typography variant="h5" component="h5" className={classes.subtitle} color="textSecondary" gutterBottom>
                  Recent Launches
        </Typography>
                <TableContainer >
                  <Table  aria-label="recent launches">
                    <TableHead>
                      <TableRow>
                        {launch_columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {launchData.data.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {launch_columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} >
              <Paper style={{ maxHeight: 600, overflow: 'auto' }}>
                <Typography variant="h5" component="h5" className={classes.subtitle} color="textSecondary" gutterBottom>
                  Latest Releases
        </Typography>
        <TableContainer >
                  <Table  aria-label="recent launches">
                    <TableHead>
                      <TableRow>
                        {releases_columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {releaseData.data.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {releases_columns.map((column) => {
                                        const value = row[column.id];
                                      if(column.id == "release_name"){
                                        return (
                                          <TableCell  key={column.id} align={column.align}>
                                            <a href={row["link"]} target="_blank" className={classes.link}>
                                            {value}
                                            </a>
                                            </TableCell>
                                        )
                                      }
                                      return (
                                      <TableCell key={column.id} align={column.align}>{value}</TableCell>
                                      )
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.button}>
                <Button color="primary" variant="contained" target="_blank" href="https://www.notion.so/infeedohq/What-s-New-in-Amber-cb8d90f593be4863b7794bebb72ccf38">View All <ArrowRightIcon /></Button>
                </div>
              </Paper>

            </Grid>
          </Grid>
        </section>
      </div>

    )
  }
  if (insightsData.isError || launchData.isError) {
    const err_msg = insightsData.errorMessage || launchData.errorMessage || releaseData.isError;
    openErrorSnackbar({ message: err_msg });
    return (
      <ErrorComponent />
    )
  }
  return (
    <div className={classes.root}>
      <LoadingComponent loading_message = 'converting bug to a feature...'/>
    </div>
  )
}

