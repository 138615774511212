
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import useDataApi from '../hooks/useDataFetchApi';
import LoadingComponent from "./ReusableComponent/LoadingComponent";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ClientTableComponent from "./ClientsTableComponent";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import _axios from '../helpers/Axios';
import NotificationComponent, { openSnackbar } from './ReusableComponent/NotificationComponent';
import ErrorComponent, { openErrorSnackbar } from './ReusableComponent/ErrorComponent';
import handleException from '../helpers/ErrorHandling';
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
  appBar: {
    letterSpacing: '1px',
    fontWeight: '600',
    opacity: '0.8'
  },
  searchBar: {
    width: '30rem',
    margin: '1.5rem'
  }
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="h4" component="h4" gutterBottom>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


class ClientPage extends React.Component {
  state = {
    active_client_data: [],
    inactive_client_data: [],
    value: 0,
    query: '',
    filtered_active_client_data: [],
    filtered_inactive_client_data: [],
  }
  componentDidMount() {
    this.initialLoad(`/api/dashboard/all/clients/true`, '/api/dashboard/all/clients/false');
  }

  initialLoad = async (active_url, inactive_url) => {
    try {
      let [active_client_data, inactive_client_data] = await Promise.all([_axios.get(active_url), _axios.get(inactive_url)]);
      this.setState({ active_client_data: active_client_data.data.data, inactive_client_data: inactive_client_data.data.data, filtered_active_client_data :active_client_data.data.data,  filtered_inactive_client_data:  inactive_client_data.data.data })
    } catch (error) {
      let err_msg = handleException(error, 'Failed to get client list');
      openErrorSnackbar({ message: err_msg });
    }
  }
  handleChange = (event, newValue) => {
    this.setState({ value: newValue, query:''})
  };
  filterData = (event) => {
    const { value,  active_client_data, inactive_client_data  } = this.state;
    this.setState({ query: event.target.value })
    let query = event.target.value.toLowerCase();
    if (value == 0) {
      this.setState({
        filtered_active_client_data:  active_client_data.filter(client_name => (client_name.title && client_name.title.toLowerCase().includes(query)))
      })
    }
    if (value == 1) {
      this.setState({
        filtered_inactive_client_data:  inactive_client_data.filter(client_name => (client_name.title && client_name.title.toLowerCase().includes(query)))
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { active_client_data, inactive_client_data, value, query , filtered_active_client_data, filtered_inactive_client_data} = this.state;
    if (active_client_data.length || inactive_client_data.length) {
      return (
        <div className={classes.root}>
          <NotificationComponent />
          <ErrorComponent />
          <Paper className={classes.paper}>
            <AppBar position="static" className={classes.appBar}>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={this.handleChange}
              aria-label="clients list"
              color="inherit"
            >
              <LinkTab label="Active Clients" />
              <LinkTab label="Inactive Clients" />
            </Tabs>
            </AppBar>
            <div>
              <TextField
                label="Search Customer"
                value={query}
                onChange={this.filterData}
                className={classes.searchBar}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <TabPanel value={value} index={0}>
              <ClientTableComponent data={filtered_active_client_data} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ClientTableComponent data={filtered_inactive_client_data} />
            </TabPanel>
          </Paper>
        </div>

      )
    }
    return (
      <div className={classes.root}>
        <NotificationComponent />
        <ErrorComponent />
        <LoadingComponent />
      </div>
    )
  }
}

export default withStyles(useStyles)(ClientPage)