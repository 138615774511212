import React, { useContext } from 'react';
import AppRouter from './routers/AppRouter';
import "./App.css";
import 'react-dates/lib/css/_datepicker.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core';
import { ThemeContext } from "./contexts/theme.context";

function App() {
  if (!localStorage.getItem("mode")) {
    localStorage.setItem("mode", "light");
  }
  const { theme } = useContext(ThemeContext);
  let themeConfig = createMuiTheme(theme);
  themeConfig = responsiveFontSizes(themeConfig);
  return (
    <React.Fragment>
      <MuiThemeProvider theme={themeConfig}>
        <CssBaseline />
        <AppRouter />
      </MuiThemeProvider>
    </React.Fragment>
  );
}
export default App;
