
import { useState } from "react";
function useDarkState(themeObject) {
    const [theme, setTheme] = useState(themeObject);
    const { palette: { type } } = theme;
    const toggleDarkMode = () => {
        const updatedTheme = {
            ...theme,
            palette: {
                ...theme.palette,
                type: type === "light" ? 'dark' : 'light'
            }
        }
        localStorage.setItem("mode", updatedTheme.palette.type);
        setTheme(updatedTheme)
    }
    return [theme, toggleDarkMode]
}
export default useDarkState;