import React from 'react';
// import 'react-dates/initialize';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LoadingComponent from "./ReusableComponent/LoadingComponent";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import _axios from '../helpers/Axios';
import ChatIcon from '@material-ui/icons/Chat';
import GroupIcon from '@material-ui/icons/Group';
import Grid from '@material-ui/core/Grid';
import Divider from "@material-ui/core/Divider";
import NotificationComponent, { openSnackbar } from './ReusableComponent/NotificationComponent';
import ErrorComponent, { openErrorSnackbar } from './ReusableComponent/ErrorComponent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { DateRangePicker } from 'react-dates';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import handleException from '../helpers/ErrorHandling';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';


const moment = require('moment');
const useStyles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1px'
    },
    title: {
        padding: '1rem',
        fontWeight: '600',
        marginBottom: '1rem'
    },
    status: {
        height: '15px',
        width: '50px',
        borderRadius: '500px',
        boxShadow: '0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24)'
    },
    client_info: {
        marginLeft: '15px',
        padding: '10px',
        width: '100%',
    },
    export_wrap:{
        display:'flex',
        flexDirection:'column'
    },
    export: {
        // padding: '10px'
        marginTop: '5px',
        display: 'flex',
        flexDirection: 'column'
    },
    info_icon:{
        position:'relative',
        top:'5px',
        cursor:'pointer'
    },
    btn: {
        margin: '5px 0',
    },
    radiobtn: {
        display: 'flex',
        flexDirection: 'row'
    },
    client_sub_text: {
        padding: '10px'
    },
    stepbtn: {
        textAlign: 'right',
        marginTop: '1rem'
    },
    formbtn: {
        textAlign: 'center'
    },
    columnbtn : {
        textAlign: 'center',
        margin: '0 10px'
    },
    user_btn: {
        padding: '10px 0'
    },
    dialog: {
        height: '90%'
    },
    date_btn : {
        // height:'20rem',
        // textAlign:'center'
        marginTop:'1rem'
    },
    switch_btn : {
        marginLeft: 0
    }
});


// export default function ClientAnalysisPage(props) {
class ClientAnalysisPage extends React.Component {
    state = {
        client_id: null,
        clients_data: {},
        survey_type: "1",
        export_option: '1',
        chat_columns: [],
        activeStep: 0,
        chat_export_steps: ['Choose Survey Type', 'Select Export Columns', 'Choose Export Option'],
        chat_export_options : ['Export', 'With Chat', 'With Notes'],
        calendarFocused: null,
        startDate: moment().subtract(1, 'year'),
        endDate: moment(),
        checkedValues: [],
        open: false,
        sincelaunch : false,
        selectAll: false,
        chat_option : 0,
        chat_export_open: false,
        selectedIndex: 0
    }


    componentDidMount() {
       // const client_id = this.props.match.params.client_id;
        const client_id = this.props.client_id;
        this.setState({ client_id: client_id });
        this.initialLoad( `/api/exports/get_columns_for_chat_export?survey_type=1`);
    }

    initialLoad = async ( columns_url) => {
        const  columns_result = await  _axios.get(columns_url);
        this.setState({ clients_data: this.props.api_data.data.data, chat_columns : columns_result.data.data })
    }

    exportUsers = async () => {
        try{
        let response = await _axios.get(`/api/exports/export_users?client_id=${this.state.client_id}`);
        openSnackbar({ message: response.data.message });
        }catch(error){
            let err_msg = handleException(error, 'Export Failed');  
            openErrorSnackbar({ message: err_msg });
        }
    }

    exportChats = async () => {
       try{ 
        this.handleClose()
        let { startDate, endDate, survey_type, checkedValues, client_id, export_option } = this.state;
        let response;
        let fields =  encodeURI(checkedValues.join(","));
        if(export_option =='2'){
        response = await _axios.get(`/api/exports/export_chats_details?client_id=${client_id}&survey_type=${survey_type}&with_chat=true`, { headers: { StartDate: moment(startDate).unix(), EndDate: moment(endDate).unix(), Fields: fields } });
        }else if(export_option =='3'){
        response = await _axios.get(`/api/exports/export_chats_details?client_id=${client_id}&survey_type=${survey_type}&with_notes=true`, { headers: { StartDate: moment(startDate).unix(), EndDate: moment(endDate).unix(), Fields: fields } });
        }else {
        response = await _axios.get(`/api/exports/export_chats_details?client_id=${client_id}&survey_type=${survey_type}`, { headers: { StartDate: moment(startDate).unix(), EndDate: moment(endDate).unix(), Fields: fields } });
        }
        openSnackbar({ message: response.data.message });
       }catch(error){
        let err_msg = handleException(error, 'Export Failed');  
        openErrorSnackbar({ message: err_msg });
       }
    }

    exportAnonymousChats = async () => {
        try{ 
         let { startDate, endDate,   client_id } = this.state;
         let response = await _axios.get(`/api/exports/export_anonymous_conversations?client_id=${client_id}`, { headers: { StartDate: moment(startDate).unix(), EndDate: moment(endDate).unix() } });
         openSnackbar({ message: response.data.message });
        }catch(error){
         let err_msg = handleException(error, 'Export Failed');  
         openErrorSnackbar({ message: err_msg });
        }
     }

    handleChange = async (event) => {
        let survey_type = event.target.value;
        let columns_result = await _axios.get(`/api/exports/get_columns_for_chat_export?survey_type=${survey_type}`);
        this.setState({ 
            survey_type: survey_type,
            chat_columns: columns_result.data.data
        })
    }
    handleExportOption = (event) => {
        let export_option = event.target.value;
        this.setState({ 
            export_option: export_option
        })
    }
    handleNext = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1
        });
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    onDatesChange = ({ startDate, endDate }) => {
        this.setState(() => ({ startDate: startDate, endDate: endDate, sincelaunch:false}));
    }
    onFocusChange = (calendarFocused) => {
        this.setState(() => ({ calendarFocused }));
    }


    handleSelect(checkedName) {
        const { checkedValues } = this.state;
        const newNames = checkedValues?.includes(checkedName)
            ? checkedValues?.filter(name => name !== checkedName)
            : [...(checkedValues ?? []), checkedName];
        this.setState(() => ({ checkedValues: newNames }));
    }

    handleClickOpen = () => {
        this.setState(() => ({ open: true, checkedValues: [], activeStep: 0, selectAll: false}));
    };

    handleClose = () => {
        this.setState(() => ({ open: false }));

    };
    handleDateChange = () => {
        let start_date = !this.state.sincelaunch ? moment.unix(1420092384) : moment().subtract(1, 'year');
        this.setState(() => ({ sincelaunch: !this.state.sincelaunch,  startDate: start_date, endDate: moment() }));
    }
    handleSelectAll = () => {
        let checkedValues = !this.state.selectAll ?  [...this.state.chat_columns] : [];
        let selectAll = !this.state.selectAll;
        this.setState(() => ({checkedValues: checkedValues, selectAll:selectAll}));
    }
  


    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <div className={this.props.classes.formbtn}>
                        <FormControl component="fieldset" >
                            <RadioGroup aria-label="survey_type" name="survey_type" value={this.state.survey_type} onChange={this.handleChange} className={this.props.classes.radiobtn}>
                                <FormControlLabel value="1" control={<Radio />} label="Tenure" />
                                <FormControlLabel value="3" control={<Radio />} label="Crisis" />
                                {/* <FormControlLabel value="2" control={<Radio />} label="MTM" /> */}
                            </RadioGroup>
                        </FormControl>
                    </div>);
            case 1:
                return (
                    <div className={this.props.classes.columnbtn}>
                      <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.selectAll}
                                                    name="names"
                                                    onChange={this.handleSelectAll}
                                                />
                                            }
                                            label="Select All"
                                        />
                        <FormGroup row>
                            <Grid container spacing={3}>
                                {this.state.chat_columns.map((column_name) => (
                                    <Grid container item sm={3} spacing={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedValues.includes(column_name)}
                                                    name="names"
                                                    onChange={() => this.handleSelect(column_name)}
                                                />
                                            }
                                            label={column_name}
                                            key={column_name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </FormGroup>
                    </div>
                );
                case 2 : 
                return (
                    <div className={this.props.classes.formbtn}>
                        <FormControl component="fieldset" >
                            <RadioGroup aria-label="export_option" name="export_option" value={this.state.export_option} onChange={this.handleExportOption} className={this.props.classes.radiobtn}>
                                <FormControlLabel value="1" control={<Radio />} label="Export" />
                                <FormControlLabel value="2" control={<Radio />} label="Export with Chat" />
                                <FormControlLabel value="3" control={<Radio />} label="Export with Notes" />
                            </RadioGroup>
                        </FormControl>
                    </div>);
            default:
                return 'Unknown stepIndex';
        }
    }


    render() {
        const { classes } = this.props;
        const { clients_data, chat_export_steps, activeStep } = this.state;
        return (
            <div>
                <NotificationComponent />
                <ErrorComponent />
                {(clients_data && clients_data.length > 0) ?
                    <div className={classes.root}>
                        {/* <Typography variant="h3" component="h3" className={classes.title} color="textSecondary" gutterBottom>
                            {clients_data[0].title}
                        </Typography> */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={5} spacing={2}>
                                <Paper className="center-align-div-content">
                                    <div className={classes.client_info}>
                                        <Typography variant="h5" color="secondary">
                                            <b>Customer Info</b>
                                        </Typography>
                                        <Divider />
                                        <div className={classes.client_sub_text}>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Active Users:</b> {clients_data[0].active_users}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Inctive Users:</b> {clients_data[0].inactive_users}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Launch Date:</b> {clients_data[0].launch_date}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Billing Cycle: </b>{clients_data[0].billing_cycle}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Payment Status: </b>{clients_data[0].payment_status}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Subscription Type:</b> {clients_data[0].subscription_type}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Subscription Start Date:</b> {clients_data[0].subscription_start_date || '-'}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <b>Subscription End Date:</b> {clients_data[0].subscription_end_date || '-'}
                                            </Typography>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={7} spacing={2}>
                                <Paper className="center-align-div-content">
                                    <div>
                                        <div className={classes.client_info}>
                                            <div className={classes.export_wrap}> 
                                            <Typography variant="h5" color="secondary">
                                                <b>Export Actions</b>
                                            </Typography>
                                            <div className={classes.date_btn}>
                        <DateRangePicker
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onDatesChange={this.onDatesChange}
                            focusedInput={this.state.calendarFocused}
                            onFocusChange={this.onFocusChange}
                            showClearDates={true}
                            numberOfMonths={1}
                            displayFormat={() => "DD/MM/YYYY"}
                            isOutsideRange={() => false}
                            focused={true} 
                        />
                        <FormControlLabel className={this.props.classes.switch_btn}
                            control={
                                <Switch
                                    checked={this.state.sincelaunch}
                                    onChange={this.handleDateChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Since launch"
                        />
                    </div>
                    </div>
                                            <div className={classes.export}>
                                                <div className={classes.user_btn}>
                                                    <Typography variant="subtitle1" color="textSecondary">
                                                        <b>Users Export</b>
                                                        <Tooltip title="Date range not applicable">
                                                        <InfoIcon  className={classes.info_icon}/>
                                                        </Tooltip>
                                                    </Typography>
                                                    <Button className={classes.btn} variant="contained" size="large" color="primary" startIcon={<GroupIcon />} onClick={() => this.exportUsers()}>Export All Users</Button>
                                                </div>
                                                <Divider />
                                                <div className={classes.user_btn}>
                                                <Typography variant="subtitle1" color="textSecondary">
                                                    <b>Chat Export</b>
                                                </Typography>
                                                <Button className={classes.btn} variant="contained" size="large" color="secondary" startIcon={<ChatIcon />} onClick={() => this.handleClickOpen()}>Export Chats</Button>
                                                <Dialog
                                                    // fullScreen={fullScreen}
                                                    fullWidth= {true}
                                                    maxWidth="md"
                                                    open={this.state.open}
                                                    onClose={this.handleClose}
                                                    aria-labelledby="responsive-dialog-title"
                                                    className={classes.dialog}
                                                >
                                                    <DialogTitle id="responsive-dialog-title">{"Export Steps"}</DialogTitle>
                                                    <Divider />
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            <div>
                                                                <Stepper activeStep={activeStep} alternativeLabel>
                                                                    {chat_export_steps.map((label) => (
                                                                        <Step key={label}>
                                                                            <StepLabel>{label}</StepLabel>
                                                                        </Step>
                                                                    ))}
                                                                </Stepper>
                                                                {this.getStepContent(activeStep)}
                                                                <Divider />
                                                                <div className={classes.stepbtn}>
                                                                    <Button
                                                                        disabled={activeStep === 0}
                                                                        onClick={this.handleBack}
                                                                        className={classes.backButton}
                                                                    >
                                                                        Back
                                                </Button>
                                               
                                                                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                                                                        {activeStep === chat_export_steps.length - 1 ? 
                                                                        <span onClick={this.exportChats}>Export</span>
                                                                        : 
                                                                        'Next'}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                </Dialog>
                                                </div>
                                                <Divider />
                                                <div className={classes.user_btn}>
                                                <Typography variant="subtitle1" color="textSecondary">
                                                    <b>Anonymous Conversation Export</b>
                                                </Typography>
                                                <Button className={classes.btn} variant="contained" size="large" color="primary" startIcon={<VisibilityOffIcon />} onClick={() => this.exportAnonymousChats()}>Export Anonymous Chats</Button>
                                                </div>
                                            </div>
                                        </div>
                

                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div> :
                    <div>
                        {clients_data && clients_data.length == 0 ?
                            <div className={classes.root}>
                                <Typography variant="h5" component="h5" className={classes.title} color="secondary" gutterBottom>
                                    No data available
                 </Typography>
                            </div> :
                            <div className={classes.root}>
                                <LoadingComponent />
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}
export default withStyles(useStyles)(ClientAnalysisPage)