
const handleException = (error, fallback_msg = 'Something went wrong', use_fallback_msg_as_default = false) => {
    const error_msg = getErrorMessage(error, fallback_msg);
    return error_msg;
}

const getErrorMessage = (error, fallback_msg) => {
    if (error.data.error) {
        return error.data.error;
    }
    if (typeof error.data === 'string') {
        return error.data;
    }
    switch (error.data.status) {
        case 429:
            return 'Too many login attempts';
        case 401:
            return 'Session Expired';
        default:
            return (fallback_msg.length ? fallback_msg : 'Something went wrong');
    }
}

export default handleException;