import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import amber_loading_img from '../../images/amber_loading.png'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '40%',
    position: 'relative',
    margin: '15rem auto',
    display: 'flex'
  },
  loader: {
    top: '40vh',
    left: '40vh'
  },
  right: {
    position: 'relative',
    background: 'white',
    // textAlign: 'right',
    minWidth: '45%',
    padding: '10px 15px',
    borderRadius: '6px',
    border: '1px solid #ccc',
    float: 'right',
    height: '3rem',
    color: '#c72928',
    letterSpacing: '1px',
    fontWeight: '600',
    fontSize: '0.8rem',
    boxShadow:'0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193, .4)',
    top: '2.5rem',
    '&::before': {
      content: '""',
      position: 'absolute',
      visibility: 'visible',
      top: '-1px',
      left: '-10px',
      border: '10px solid transparent',
      borderTop: '10px solid #ccc',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      visibility: 'visible',
      top: '0px',
      left: '-8px',
      border: '10px solid transparent',
      borderTop: '10px solid white',
      clear: 'both'
    }
  },
  amber_img: {
    width: '7rem',
    height: '7rem',
    borderRadius:'40%'
    // margin:'10px auto'
  },

'@keyframes placeHolderShimmer': {
  '0%': {
      backgroundPosition: '0 0',
  },

  '100%': {
      backgroundPosition: '10000px 0'
  }
},

animated_background: {
  animationDuration: '10s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  animationName: `$placeHolderShimmer`,
  background: '#f6f7f8',
  background: 'linear-gradient(to right, #eeeeee 8%, #aacfde 18%, #eeeeee 33%)',
  height: '7px',
  top:'5px',
  position: 'relative',
},

background_masker :{
  background: '#fff',
  position: 'absolute'
},
content_third_line:{
  top: '40px',
  left: '0',
  right: '0',
  height: '6px',
},
content_third_end:{
  top: '40px',
  left: '0',
  right: '0',
  height: '6px',
},
content_third_line: {
  top: '82px'
},
content_third_end: {
  left: '300px',
  top: '88px'
}
}))

export default function LoadingComponent(props) {
  const classes = useStyles();
  let random_messsage = "";
  const loading_messages = [
    'Why so serious?',
    'Hold tight, this too shall load!',
    'Measuring cable length to fetch data...',
    'I swear it\'s almost done.',
    'Hey there, is this still loading?',
    'Shovelling coal into the server.',
    'Loading humorous message ... Please Wait',
    'Brace yourself. Data coming in 5,4,3...',
    'Still faster than other dashboards',
    'You are number 2843684714 in the queue',
    'Optimizing the optimizer...',
    'converting bug to a feature...',
    'We\'re testing your patience',
    'As if you had any other choice',
    'Replace with realtime client metric?',
    'Preparing the Matrix',
    'We\'re making you a cookie.',
    'Downloading more RAM..',
  ]
  if (props.loading_message) {
    random_messsage = props.loading_message
  } else {
    random_messsage = loading_messages[Math.floor(Math.random() * loading_messages.length)];
  }
  return (
    <div >
      {/* <LinearProgress className = {classes.loader} color="secondary"/> */}
      <div className={classes.root}>
      <img src={amber_loading_img} className={classes.amber_img} alt="Amber loader"></img>
      <div className={classes.right}>
        <span>{random_messsage}</span>
        <div className={classes.timeline_item}>
        <div className={classes.animated_background}>
            <div  className={`${classes.background_masker} ${classes.content_third_line}`}></div>
            <div  className={`${classes.background_masker} ${classes.content_third_end}`}></div>
        </div>
    </div>
      </div>
      </div>
 
    </div>
  );
}