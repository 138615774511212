import axios from 'axios';
import config from '../config';
// 'http://34.236.78.123:9300',
// create new axios instance
const _axios = axios.create({
    baseURL: config.url
    });

// interceptor to send auth token 
// ------------------------------
_axios.interceptors.request.use((config_axios) => {
    if(localStorage.token){
        config_axios.headers.Authorization = `Bearer ${localStorage.token}`;
    }
    return config_axios;
}, (error) => {
    return Promise.reject(error);
}
)

// logout on 401
// -------------

_axios.interceptors.response.use((response) => {
    return response;
},(error)=>{
    const {status} = error.response;
    const { url } = error.response.config;
    if (status === 401 && !url.includes('authenticate')) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
        setTimeout(() => {
            window.location.reload(true);
        },2000)
    }else if(status === 302){
        sessionStorage.clear();
        if(error.response.data && error.response.data.token){
            if(localStorage.token){
                localStorage.token = error.response.data.token;
            }else {
                localStorage.setItem('token', error.response.data.token);
            }
        }
        return window.location.href = `/${error.response.data.token}`;
    } 
    return Promise.reject(error);
})

export default _axios;