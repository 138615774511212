import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bg_cover: {
      // background: 'url(https://blogs-static-assets.s3.amazonaws.com/cover.jpg)',
      background:'#D7D7D7',
      backgroundSize: 'cover',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
  },
  card_div: {
      borderRadius: '3px',
      padding: '10px',
      textAlign: 'center',
      width: '25rem',
      overflow: 'visible',
      boxShadow:'0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193, .4)'
  },
  login_icon:{
    backgroundColor:'#c72928',
    color:'#fff',
    position:'relative',
    top: "-60px",
    height: '7rem',
    boxShadow:'0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193, .4)'
  },
  login_txt : {
    fontWeight:'600',
    letterSpacing:'1px',
    paddingTop: '1rem',
  },
  avatar:{
    color:'white',
    margin: '1rem auto',
  },
  amber_img:{
    width:'12rem',
    height:'12rem',
    margin:'10px auto'
  },
  btn_slack:{
    display:'flex',
    flexDirection:'column',
    position:'relative',
    top:'-30px'
  }
  }));

  export default useStyles;