import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '7rem',
    overflow: 'visible'
  },
  cardCategory: {
    // color: grayColor[0],
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: "10px",
    color: theme.palette.text.secondary,
    // overflow: 'visible'
  },
  icon: {
    padding: "15px 20px",
    // background: "tomato",
    position: "relative",
    top: "-30px",
    height:'5rem',
    opacity: '0.8',
    boxShadow:'0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193, .4)'
  },
  pos:{
    display:'block',
    // textAlign:'right'
  }
}));

export default useStyles;