import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
    title:{
      fontWeight:'600',
      marginBottom:'2rem'
    },
    subtitle:{
      margin:'1rem 0 0 1rem',
      fontWeight:'600',
      marginBottom:'1rem'
    },
    insight:{
      // padding: '1rem 0',
      marginBottom:'2rem'
    },
    button:{
      padding:'1rem',
      textAlign:'right'
    },
    link:{
      textDecoration: 'none',
      color:'inherit'
    }
  }));

  export default useStyles;