import React, { forwardRef } from 'react'
import ListItem from '@material-ui/core/ListItem'
import { NavLink } from 'react-router-dom'


const SidebarMenuItemComponent =props => {
    const { className, onClick, link, children } = props
   // Return a LitItem with a link component
   if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    )
  }
    return (
      <ListItem
        button
        className={className}
        children={children}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={link}
      />
    )
  }
  
  export default SidebarMenuItemComponent;
  