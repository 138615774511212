import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SidebarMenuItemComponent from './SidebarMenuItemComponent'
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
}

const SidebarMenuItem = props => {
  const { name, link, Icon, items = [] } = props
  const classes = useStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
  }

  const MenuItemRoot = (
    <SidebarMenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText color="textSecondary" primary={name} inset={!Icon} className={classes.textItem} />
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </SidebarMenuItemComponent>
  )

  // Used for nested children in sidebar
  // const MenuItemChildren = isExpandable ? (
  //   <Collapse in={open} timeout="auto" unmountOnExit>
  //     <Divider />
  //     <List component="div" disablePadding>
  //       {items.map((item, index) => (
  //         <SidebarMenuItem {...item} key={index} />
  //       ))}
  //     </List>
  //   </Collapse>
  // ) : null

  return (
    <>
      {MenuItemRoot}
      {/* {MenuItemChildren} */} 
    </>
  )
}


const useStyles = makeStyles(theme =>
  createStyles({
    textItem: {
      "& span": {
        // letterSpacing: '1px',
        fontSize: '1.2rem',
      }
    },
    menuItem: {
      transition: 'all 300ms linear',
      width: 'auto',
      margin: '10px 10px 0',
      padding: '10px',
      '&.active': {
        lineHeight: '1.5em',
        boxShadow: '0 12px 20px -10px rgba(0, 172, 193,.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(0, 172, 193,.2)',
        backgroundColor: '#c72928',
        opacity: '0.6',
        borderRadius: '5px',
        color:'white'
      },
    },
    menuItemIcon: {
      minWidth: '35px',
    }
  }),
)

export default SidebarMenuItem;
