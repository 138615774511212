import React from 'react';
import {  withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LoadingComponent from "./ReusableComponent/LoadingComponent";
import BackupIcon from '@material-ui/icons/Backup';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import _axios from '../helpers/Axios';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import NotificationComponent, { openSnackbar } from './ReusableComponent/NotificationComponent';
import ErrorComponent, { openErrorSnackbar } from './ReusableComponent/ErrorComponent';
import handleException from '../helpers/ErrorHandling';
const useStyles = (theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '15rem',
    },
    uploadButton: {
        marginTop: '2rem',
    },
    title: {
        fontWeight: '600',
        marginBottom: '2rem'
    },
});

class MailgunPage extends React.Component {
    state = {
        domains: [],
        selectDomain: 'Domain List',
        open: false,
    }
    componentDidMount() {
        //calling a fn initial load to initial load the data
        this.initialLoad();
    }

    initialLoad = async () => {
        await this.domainsData(`/api/mailgun/domains`);
    }
    domainsData = async (url) => {
        const result = await _axios.get(url);
        this.setState({ domains: result.data, selectDomain: result.data[0] })
    }
    handleChange = (event) => {
        this.setState({ selectDomain: event.target.value })
    }
    handleClick = async () => {
        let response = await _axios({
            url: `/api/mailgun/domains/get_sample_bounce_list_file`,
            method: 'GET',
            responseType: 'blob',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `sample_file.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
    onFileUpload = async (event) => {
        try{
        const { selectDomain } = this.state;
        const file = Array.from(event.target.files)
        const formData = new FormData();
        formData.append('csv', file[0]);
        let response = await _axios.post(`/api/mailgun/domains/${selectDomain}/delete_bounce_addresses`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        openSnackbar({ message: response.data});
    }catch(error){
        let err_msg = handleException(error, 'Delete Bounce Email Failed'); 
        openErrorSnackbar({ message: err_msg});
    }
    }
    render() {
        const { classes } = this.props;
        const { domains, selectDomain } = this.state;
        if (domains.length > 0) {
            return (
                <div className={classes.root}>
                    <div>
                    <NotificationComponent />
                    <ErrorComponent />
                        <Typography variant="h5" component="h5" className={classes.title} color="textSecondary" gutterBottom>
                            Mailgun Actions
                        </Typography>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Domain List</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectDomain}
                                onChange={this.handleChange}
                            >
                                {domains.map((domain_name) => (
                                    <MenuItem value={domain_name}>{domain_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.uploadButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleClick}
                            className={classes.button}
                            startIcon={<CloudDownloadIcon />}
                        >
                            Download Sample File
                        </Button>
                        </div>
                        <div className={classes.uploadButton}>
                        <input
                            type="file" accept=".csv"
                            className={classes.input}
                            style={{ display: 'none' }}
                            onChange={this.onFileUpload}
                            id="raised-button-file"
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span" color="secondary"
                                className={classes.button}
                                startIcon={<BackupIcon />}>
                                Upload Emails
                            </Button>
                        </label>
                    </div>
                </div>
            )
        }

        return (
            <div className={classes.root}>
                <LoadingComponent />
            </div>
        )

    }
}
export default withStyles(useStyles)(MailgunPage)
