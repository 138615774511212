import React, { createContext } from "react";
import useDarkMode from "../hooks/useDarkState";
export const ThemeContext = createContext();
/*
Setup Global Theme Object of application
*/
const themeObject = {
  palette: {
    primary: { main: "#213f6c" },
    secondary: { main: "#c72928" },
    type: localStorage.getItem("mode") || "light"
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  themeName: 'Blue Lagoon 2020',
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'
    ].join(','),
    fontSize: 12,
    spacing: [0, 4, 8, 16, 32, 64],
  }
}
export function ThemeProvider(props) {
  const [theme, toggleDarkMode] = useDarkMode(themeObject);
  return (
    <ThemeContext.Provider value={{ theme, toggleDarkMode }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
