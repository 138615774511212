import React from 'react';
import {  Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import LoginPage from '../components/LoginPage';
import AutoLogin from '../components/AutoLogin';
import SideBar from '../components/SideBar';
import HomePage from '../components/HomePage';
import ClientsPage from '../components/ClientsPage';
import IntegrationsPage from '../components/IntegrationsPage';
import DriverElementPage from '../components/DriverElementPage';
import ReleasesPage from '../components/ReleasesPage';
import AccessSetupPage from '../components/AccessSetup';
import AccessDeniedPage from '../components/AccessDeniedPage';
import MailgunPage from '../components/MailgunPage';
// import NotFoundPage from '../components/NotFoundPage';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import PrivateRoute from './PrivateRoute';
import RedirectPage from '../components/Redirect'
import ClientInfo from '../components/ClientInfo';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	  },
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto"
	},
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4)
	},
}));



export default function AppRouter() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/" component={LoginPage} exact />
				<Route path="/redirect" component={RedirectPage} exact />
				<Route  path="/access-denied" component={AccessDeniedPage} exact />
				<Route exact path="/:token" component={AutoLogin} />
				<Route component={DefaultContainer} />
				<Redirect to="/internal/dashboard/home" />
				{/* <Redirect to="/access-denied" /> */}
			</Switch>
		</BrowserRouter>
	)
}

const DefaultContainer = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Route component={SideBar} />
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Route path="/internal/dashboard/home" component={HomePage} exact />
					<PrivateRoute path="/internal/dashboard/clients" component={ClientsPage} exact />
					<PrivateRoute path="/internal/dashboard/clients_info/:client_id" component={ClientInfo} exact />
					<PrivateRoute path="/internal/dashboard/integrations" component={IntegrationsPage} exact />
					<PrivateRoute path="/internal/dashboard/drivers_elements" component={DriverElementPage} exact />
					<PrivateRoute path="/internal/dashboard/releases" component={ReleasesPage} exact />
					<PrivateRoute path="/internal/dashboard/mailgun" component={MailgunPage} exact />
					<PrivateRoute path="/internal/dashboard/access-setup" component={AccessSetupPage} exact />
					{/* <Route component={NotFoundPage} /> */}
				</Container>
			</main>
		</div>
	)
};
