import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let openSnackbarFn;

class ErrorComponent extends React.Component {
  state = {
    error_open: false,
    message: '',
    status: ''   
  };

  componentDidMount() {
    openSnackbarFn = this.openErrorSnackbar;
  }

  openErrorSnackbar = ({ message }) => {
    this.setState({
      error_open: true,
      message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      error_open: false,
      message: '',
    });
  };

  render() {
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // message={message}
        autoHideDuration={2000}
        onClose={this.handleSnackbarClose}
        error_open={this.state.error_open}
        ContentProps={{
          'aria-describedby': 'snackbar-message-id',
        }}
        >
        <Alert severity="error">
        {message}
    </Alert>
    </Snackbar>
    );
  }
}

export function openErrorSnackbar({ message }) {
  openSnackbarFn({ message });
}

export default ErrorComponent;