import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import LoadingComponent from "./ReusableComponent/LoadingComponent";
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import _axios from '../helpers/Axios';
import ErrorComponent, { openErrorSnackbar } from './ReusableComponent/ErrorComponent';
import handleException from '../helpers/ErrorHandling';
const useStyles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '1px'
    },
    title: {
        padding: '1rem',
        fontWeight: '600',
        marginBottom: '1rem'
    },
    panelContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    element_root: {
        display: 'flex',
        marginTop: '1rem',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
});

class ClassComponent extends React.Component {

    //defining initial state
    state = {
        apfdemap: {},
        apfDrivers: {},
        classes: [],
        apfElements: []
    }


    componentDidMount() {
        //calling a fn initial load to initial load the data
        this.initialLoad();
    }

    initialLoad = async () => {
        await this.driverData(`/api/dashboard/apf_driver`);
        await this.elementData(`/api/dashboard/apf_driver/1/elements`)
        this.setState({ apfdemap: { ...this.state.apfdemap, [1]: this.state.apfElements.data } })
    }

    driverData = async (url) => {
        try {
            const result = await _axios.get(url);
            this.setState({ apfDrivers: result.data })
        } catch (error) {
            let err_msg = handleException(error, 'Failed to get drivers');
            openErrorSnackbar({ message: err_msg });
        }
    }

    elementData = async (url) => {
        try {
            const result = await _axios.get(url);
            this.setState({ apfElements: result.data })
        } catch (error) {
            let err_msg = handleException(error, 'Failed to get elements');
            openErrorSnackbar({ message: err_msg });
        }
    }

    showElements = async (driver_id) => {
        await this.elementData(`/api/dashboard/apf_driver/${driver_id}/elements`)
        this.setState({ apfdemap: { ...this.state.apfdemap, [driver_id]: this.state.apfElements.data } })
    }

    stopPropagation = (e) => e.stopPropagation();

    render() {
        const { classes } = this.props;
        const { apfdemap, apfDrivers } = this.state;


        return (
            <div>
                {apfDrivers.data ?
                    <div className={classes.root}>
                        <ErrorComponent />
                        <Paper>
                            <Typography variant="h4" component="h4" className={classes.title} color="secondary" gutterBottom>
                                Action Planning Framework
                    </Typography>
                            {apfDrivers.data.map((driver) => {
                                return (
                                    <div onClick={this.stopPropagation}>
                                        <ExpansionPanel key={driver.id} TransitionProps={{ unmountOnExit: true }} onClick={() => { this.showElements(driver.id) }} >
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading} color="textPrimary">{driver.name}</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.panelContainer}>
                                                <Typography color="textSecondary">
                                                    {driver.definition}
                                                </Typography>
                                                <div className={classes.element_root}>
                                                    {apfdemap[driver.id] && apfdemap[driver.id].map((element) => {
                                                        return (
                                                            <Chip
                                                                label={element.name}
                                                                color="primary"
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                )
                            })}

                        </Paper>
                    </div>
                    :
                    <div className={classes.root}>
                        <LoadingComponent />
                    </div>
                }
            </div>
        )
    }
}

export default withStyles(useStyles)(ClassComponent)
