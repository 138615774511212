import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        textAlign:'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column',
        margin:'20rem 0'
    }
}));
export default function AccessDeniedPage(props) {
    const classes = useStyles();
    const info = (props.location.state && props.location.state.info) || 'Please ask admin to give access'
    return (
        <div className={classes.root}>
            <Typography variant="h4" component="h4"  color="secondary" gutterBottom>
                4<LockIcon />3 : Access Denied
        </Typography>
            <Typography variant="h6" component="h6"  color="textSecondary" gutterBottom>
                {info}
            </Typography>
        </div>
    )
}
