import React, { useState, useContext } from 'react';
import clsx from "clsx";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SidebarMenu from './SidebarMenu'
import Drawer from '@material-ui/core/Drawer'
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import { ThemeContext } from "../contexts/theme.context";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import useDataApi from '../hooks/useDataFetchApi';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LoadingComponent from "./ReusableComponent/LoadingComponent";
import logo from '../images/logo-min.png';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; 
const drawerWidth = 240;


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    right: '20%',
    bottom: '20%',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);
const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    display: 'flex',
    flexGrow: 1
    // justifyContent: 'flex-end'

  },
  toggle: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7)
    }
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  icon: {
    marginRight: '1rem',
    cursor: 'pointer'
  },
  fixedHeight: {
    height: 260
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  user_info : {
    display :'flex',
    margin: '15px auto',
    position: 'relative',
    right: '5%'
  },
  user_text: {
    margin: '15px 0 15px 15px',
    fontSize: '1.2rem',
    
  },
  logo:{
    width:120,
    marginRight: '3rem'
  }
}));

const SideBar = (props) => {
  const { theme, toggleDarkMode } = useContext(ThemeContext);
  const [loggedInUserData, setUrl] = useDataApi(`/api/users/me`);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(true);
  const menuopen = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // if (loggedInUserData.data && loggedInUserData.data.enabled == false) {
  //   return (
  //     <Redirect to="/access-denied" />
  //   )
  // }
  if (loggedInUserData.data) {
    return (
      <>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >

          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.toggle}>
              {theme.palette.type === "light" ? <Brightness7Icon className={classes.icon} onClick={toggleDarkMode} /> : <Brightness4Icon className={classes.icon} onClick={toggleDarkMode} />}
              <PowerSettingsNewIcon className={classes.icon} onClick={handleLogout} />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <img  className={classes.logo} src={logo} alt="inFeedo logo" />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <div className = {classes.user_info}>
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
            color="primary"
          >
            <Avatar alt={loggedInUserData.data.name} src={loggedInUserData.data.slack.user.image_72} className={classes.large} />
          </StyledBadge>
          <Typography variant="subtitle1" color="textSecondary" className={classes.user_text}>
             {loggedInUserData.data.name}
          </Typography>
          </div>
          <Divider />
          <SidebarMenu setup_page_access = {loggedInUserData.data.role}/>
        </Drawer>
      </>

    )
  }
  if(loggedInUserData.isError && loggedInUserData.status==403){
    return(
    <Redirect  to = {{
      pathname: '/access-denied',
      state: { info: loggedInUserData.errorMessage }
    }}/>
      )
  }
  return (
    <div className={classes.root}>
        {/* <LoadingComponent /> */}
    </div>
)
}
export default SideBar;