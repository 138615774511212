import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LoadingComponent from "./ReusableComponent/LoadingComponent";
import Paper from '@material-ui/core/Paper';
import _axios from '../helpers/Axios';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationComponent, { openSnackbar } from './ReusableComponent/NotificationComponent';
import ErrorComponent, { openErrorSnackbar } from './ReusableComponent/ErrorComponent';

import { green, orange } from '@material-ui/core/colors';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Tooltip from '@material-ui/core/Tooltip';
import handleException from '../helpers/ErrorHandling';
const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 700,
        display: 'flex',
        justifyContent: 'space-between'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    maintext: {
        fontWeight: 600
    },
    info: {
        display: 'flex',
    },
    user_info: {
        marginLeft: '1rem'
    },
    action_button: {
        margin: '0.2rem',
        fontSize: 30,
        cursor: 'pointer'
    },
    roletext: {
        textTransform: 'capitalize'
    },
});


class AccessPage extends React.Component {
    //defining initial state
    state = {
        classes: [],
        usersData: [],
        open: false,
        rerender: false
    }
    componentDidMount() {
        //calling a fn initial load to initial load the data
        this.initialLoad();
    }

    initialLoad = async () => {
        await this.userData(`/api/users`);
    }
    userData = async (url) => {
        try{
        const result = await _axios.get(url);
        this.setState({ usersData: result.data })
        }catch(error){
            let err_msg = handleException(error, 'Failed to get users');  
            openErrorSnackbar({ message: err_msg }); 
        }
    }
    handleClose = () => {
        this.setState({ open: false })
    }

    makeAdmin = async (id) => {
        try{
        let response = await _axios.patch(`/api/users/${id}/make_admin`)
        openSnackbar({ message: response.data.message });
        this.initialLoad()
        }catch(error){
            let err_msg = handleException(error, 'Failed to make an admin');  
            openErrorSnackbar({ message: err_msg });
        }
    }

    toggleUserStatus = async (id, status) => {
        try{
        let response = await _axios.patch(`/api/users/${id}/enable_or_disable_user `, {
            enabled: status
        })
        openSnackbar({ message: response.data.message });
        this.initialLoad()
    }catch(error){
        let err_msg = handleException(error, 'Unable to change user status');  
        openErrorSnackbar({ message: err_msg });
    }
    }

    deleteUser = async (id) => {
        try{
        let response = await _axios.delete(`/api/users/${id}`)
        openSnackbar({ message: response.data.message });
        this.initialLoad()
        }catch(error){
            let err_msg = handleException(error, 'Failed to delete user');  
            openErrorSnackbar({ message: err_msg });
        }
    }

    render() {
        const { classes } = this.props;
        const { usersData } = this.state;

        if (usersData.data && usersData.data.length != 0) {
            return (
                <div className={classes.root}>
                    <NotificationComponent />
                    <ErrorComponent />
                    <Grid container spacing={3}>
                        {usersData.data.map((profile_key, index) => (
                            <Grid item xs={12} sm={6} spacing={2}>
                                <Paper className={classes.paper}>
                                    <div className={classes.info}>
                                        <Avatar alt="user profile" src={profile_key.slack.user.image_72} className={classes.large} />
                                        <div className={classes.user_info}>
                                            <Typography component="h6" variant="h6" color="textPrimary" className={classes.maintext}>
                                                {profile_key.name}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {profile_key.email}
                                            </Typography>
                                            <Typography variant="subtitle1" color="textSecondary" className={classes.roletext}>
                                                Privilege: {profile_key.role.charAt(0).toUpperCase() + profile_key.role.toLowerCase().slice(1)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div >
                                        {profile_key.role !== "ADMIN" &&
                                         <Tooltip title="Admin Access">
                                            <VerifiedUserIcon className={classes.action_button} color="primary" onClick={() => this.makeAdmin(profile_key._id)}/>
                                            </Tooltip>
                                        }
                                        {!profile_key.enabled ?
                                         <Tooltip title="Give Access">
                                            <PersonAddIcon className={classes.action_button} style={{ color: green[500] }} onClick={() => this.toggleUserStatus(profile_key._id, true)} />
                                            </Tooltip>
                                            :
                                            <Tooltip title="Revoke Access">
                                            <PersonAddDisabledIcon className={classes.action_button} style={{ color: orange[500] }}   onClick={() => this.toggleUserStatus(profile_key._id, false)} />
                                            </Tooltip>
                                        }
                                        <Tooltip title="Delete User">
                                        <DeleteIcon className={classes.action_button} color="secondary" onClick={() => this.deleteUser(profile_key._id)} />
                                        </Tooltip>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )
        }
        if (usersData.data && usersData.data.length == 0) {
            return (
                <div className={classes.root}>
                    <Typography component="h6" variant="h6" color="textPrimary" >
                        No Users Available
                    </Typography>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <LoadingComponent />
            </div>
        )
    }
}

export default withStyles(useStyles)(AccessPage)