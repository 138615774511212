import React, { useEffect, useReducer, useState } from 'react'  
// import axios from 'axios';
import dataFetchReducer from '../reducers/datafetch.reducer';
import handleException from '../helpers/ErrorHandling';
import _axios from '../helpers/Axios';
const initialState = {
    isLoading: false,
    isError: false,
    data : '',
    errorMessage:'',
    status: 200
}

const useDataFetchApi = (initialUrl) => {
    const [url, setUrl] = useState(initialUrl);
    const [state, dispatch] = useReducer(dataFetchReducer, initialState);
    
    useEffect(() => {
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const result = await _axios.get(url);
          dispatch({ type: 'FETCH_SUCCESS', payload: result.data.data });
        } catch (error) {
          const data = handleException(error.response);
          const status = error.response.status;
          dispatch({ type: 'FETCH_FAILURE', payload: {data, status}});
        }
      };
      fetchData();
    }, [url]);
   return [state, setUrl];
  };

  export default useDataFetchApi;