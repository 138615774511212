import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import useStyles from "../styles/LoginStyles";
import { Card } from '@material-ui/core';
import amber_img from '../images/Amber_landing_web.png'
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CardContent from '@material-ui/core/CardContent';
import config from "../config"
function LoginPage(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.bg_cover}>
        <Card style={{overflow:'visible'}}>
          <CardContent className={classes.card_div}>
            <div className={classes.login_icon} >
              <Typography component="h1" variant="h5" className={classes.login_txt}>
                Sign in
            </Typography>
                <FingerprintIcon className={classes.avatar} fontSize="large"/>
            </div>
            <div className={classes.btn_slack}>
            <Typography component="h4" variant="h5" color="textPrimary" className={classes.login_txt}>
                Service Request Dashboard
            </Typography>
              <img src={amber_img} className={classes.amber_img} alt="Amber logo"></img>
              <a href={`${config.url}/auth/slack`}><img src="https://api.slack.com/img/sign_in_with_slack.png"  alt="Slack login"/></a>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
export default withRouter(LoginPage);