import React from 'react'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import useStyles from "../../styles/InsightComponentPageStyles";
const moment = require('moment');
export default function InsightComponent(props) {
    const classes = useStyles();
    const { titles, insight_keys, insights_data, insight_icon } = props;
    const backgroundColor = [
        'linear-gradient(60deg, #ffa726, #fb8c00)',
        'linear-gradient(60deg, #66bb6a, #43a047)',
        'linear-gradient(60deg, #ef5350, #e53935)',
        'linear-gradient(60deg, #213F6C, #4267A0)'
       ]
    return (
        <Grid container spacing={3}>
            {insight_keys.map((insight_key, index) => (
                <Grid item xs={12} sm={6} md={3}>
                    <Card className={classes.root}>
                        <CardContent className={classes.cardCategory}>
                            <div className={classes.icon} style={{ background: backgroundColor[index] }}>
                                {insight_icon[insight_key]}
                            </div>
                            <div>
                                <Typography  className={classes.title} color="textSecondary" gutterBottom>
                                    {titles[insight_key]}
                                </Typography>
                                {insights_data && insight_key!='ptm_data' &&  insight_key != 'response_rate_data'? 
                                <Typography component="h5" variant="h5" className={classes.pos} color="secondary">
                                    {insights_data[insight_key]}
                                </Typography>
                                :null
                                }
                                {insights_data && insight_key=='ptm_data'? 
                                <div>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                    <b>Open:</b> {insights_data[insight_key]['open_cases']}
                                </Typography>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                <b>In progress:</b> {insights_data[insight_key]['in_progress_cases']}
                                </Typography>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                <b>Closed:</b> {insights_data[insight_key]['closed_cases']}
                                </Typography>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                <b>Oldest open case:</b> {insights_data[insight_key]['oldest_open_case_date'] ? moment.unix(insights_data[insight_key]['oldest_open_case_date']).format("DD/MM/YY") : "-"}
                                </Typography>
                                </div>
                                :null
                                }
                                    {insights_data && insight_key=='response_rate_data'? 
                                <div>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                    <b>To start:</b> {insights_data[insight_key]['to_start_chats']}
                                </Typography>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                <b>Dropped:</b> {insights_data[insight_key]['dropped_chats']}
                                </Typography>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                <b>Completed:</b> {insights_data[insight_key]['completed_chats']}
                                </Typography>
                                <Typography className={classes.pos} variant="caption" color="textSecondary">
                                <b>Total chats:</b> {insights_data[insight_key]['total_chats'] }
                                </Typography>
                                </div>
                                :null
                                }
                                {(insight_key === "reachouts" || insight_key === "reminders") && <Typography className={classes.pos} variant="caption" color="textSecondary">
                                    *Last 24 hours
                            </Typography>}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}
