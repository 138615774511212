import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
function AutoLogin(props) {
    useEffect(() => {
        const { token } = props.match.params;
        setTokenAndRedirect(token);
    }, []);

    function setTokenAndRedirect(token) {
        if (token) {
            localStorage.setItem("token", token);
            props.history.push('/internal/dashboard/home')
        }
    }
    return (
        <div>
            <CircularProgress />
        </div>
    )
}

export default withRouter(AutoLogin);


