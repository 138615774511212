import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import SidebarMenuItem from './SidebarMenuItem'
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import ExploreIcon from '@material-ui/icons/Explore';
import SettingsIcon from '@material-ui/icons/Settings';
import MailIcon from '@material-ui/icons/Mail';
let sidebarMenuItems = [
  {
    name: 'Home',
    link: '/internal/dashboard/home',
    Icon: DashboardIcon,
  },
  {
    name: 'Clients',
    link: '/internal/dashboard/clients',
    Icon: GroupIcon,
  },
  {
    name: 'Driver Elements',
    link: '/internal/dashboard/drivers_elements',
    Icon: ExploreIcon
  },
  {
    name: 'Mailgun',
    link: '/internal/dashboard/mailgun',
    Icon: MailIcon
  },
  // {
  //   name: 'Integrations',
  //   link: '/internal/dashboard/integrations',
  //   Icon: SyncIcon
  // },
  // {
  //   name: 'Release usability',
  //   link: '/internal/dashboard/release-usability',
  //   Icon: EqualizerIcon
  // },
  
]

let sidebarAdminMenuItems = [
  {
    name: 'Home',
    link: '/internal/dashboard/home',
    Icon: DashboardIcon,
  },
  {
    name: 'Clients',
    link: '/internal/dashboard/clients',
    Icon: GroupIcon,
  },
  {
    name: 'Driver Elements',
    link: '/internal/dashboard/drivers_elements',
    Icon: ExploreIcon
  },
  {
    name: 'Mailgun',
    link: '/internal/dashboard/mailgun',
    Icon: MailIcon
  },
  // {
  //   name: 'Integrations',
  //   link: '/internal/dashboard/integrations',
  //   Icon: SyncIcon
  // },
  // {
  //   name: 'Release usability',
  //   link: '/internal/dashboard/release-usability',
  //   Icon: EqualizerIcon
  // },
  {
    name: 'Access Setup',
    link: '/internal/dashboard/access-setup',
    Icon: SettingsIcon
  } 
]


const useStyles = makeStyles(theme => ({
  list: {
    // marginTop:'1.2rem',
  }, 
}));

const SidebarMenu = props => {
  const classes = useStyles();
  sidebarMenuItems = props.setup_page_access == "ADMIN" ?  sidebarAdminMenuItems : sidebarMenuItems;
  // [...sidebarMenuItems, {
  //   name: 'Access Setup',
  //   link: '/internal/dashboard/access-setup',
  //   Icon: SettingsIcon
  // }] : sidebarMenuItems;
  return (
    <List component="nav" className={classes.list} disablePadding>
      {sidebarMenuItems.map((item, index) => (
        <SidebarMenuItem {...item} key={index} />
      ))}
    </List>
  )
}

export default SidebarMenu
