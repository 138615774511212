import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ClientAnalysisPage from './ClientAnalysisPage'
import HealthMetric from './HealthMetric'
import _axios from '../helpers/Axios';
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    padding: '1rem',
    fontWeight: '600',
    marginBottom: '1rem'
},
client_info:{
 backgroundColor: 'unset',
 boxShadow: 'none'
},
subtitle:{
  fontWeight:'500'
}
});
 
 class ClientInfo extends React.Component {
     //initial state
    state ={
        value:0,
        client_id: null,
        title: '',
        api_data: {},
        client_analysis_page: false
    }

    componentDidMount (){
        const client_id = this.props.match.params.client_id;
        this.setState({ client_id: client_id });
        this.initialLoad(`/api/dashboard/clients/insights/${client_id}`);
    }

    initialLoad = async (client_url) => {
        const result = await _axios.get(client_url);
        this.setState({api_data:result, client_analysis_page:true})
        if(result.data.data[0]){
        this.setState({title:result.data.data[0].title })
        }
    }

 handleChange = (event, newValue) => {
    this.setState({value:newValue})
 }

render()
{
    const { classes } = this.props;
    const{value,title,client_analysis_page} =this.state;
    const client_id = this.props.match.params.client_id;
  return (
      
      <div>
        <Typography variant="h3" component="h3" className={classes.title} color="textSecondary" gutterBottom>
          {title}
        </Typography>
    <Paper className={classes.root ,classes.client_info}>
      <Tabs
        value={value}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="textSecondary"
        className={classes.subtitle}
        // centered
      >
        <Tab label="Customer Info" />
        <Tab label="Health metric" />
      </Tabs>
      
    </Paper>
    {/* hr tag for horizontal line and br to break a line */}
    <hr></hr>
    <br></br>
    {(value==0 && client_analysis_page )?
    //rendering client analysis page and passing client id as prop
        <ClientAnalysisPage  client_id={client_id} api_data={this.state.api_data}/>
        :<div>
        { (value==1)?
        // rendering the health metric is value is set to 1
            <HealthMetric  client_id={client_id}/>
        : null
        }
        </div>
    }
    </div>
  );
}
}

export default withStyles(useStyles)(ClientInfo)