import React from 'react'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const columns = [
    { id: "Serial", label: "S. No.", minWidth: 60 },
    { id: 'title', label: 'Name', minWidth: 100 },
    { id: 'subscription_type', label: 'Subscription Type', minWidth: 100 },
    // {
    //     id: 'users',
    //     label: 'Active Users',
    //     minWidth: 100,
    //     align: 'right',
    // },
    {
        id: 'billing_cycle',
        label: 'Billing Cycle',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'payment_status',
        label: 'Payment Status',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'launch_date',
        label: 'Launch Date',
        minWidth: 170,
        align: 'right',
    },
];


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 800,
    },

});



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

export default function ClientTableComponent(props) {
    const classes = useStyles();
    let [index, setIndex] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    let data = props.data;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setIndex(newPage * rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        // setIndex(index);
        setPage(0);
    };

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}   >
                                    {columns.map((column) => {
                                         let value = row[column.id]; 
                                        if(column.id == "Serial"){
                                            value = ++index;
                                        }
                                        return (

                                            <>
                                                {column.id == "title" ? (<TableCell key={column.id} align={column.align} component={Link} to={`/internal/dashboard/clients_info/${row.client_id}`}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>) : <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>}
                                            </>

                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>


    )
}